@import "~bulma/sass/utilities/initial-variables";
@import "~antd/dist/antd.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

$theme: #262733;
$theme-color: #fff;
$theme-red: #FF5152;

$body-background-color: $theme;
$navbar-background-color: transparent;

$body-color: $theme-color;
$navbar-item-color: $theme-color;
$title-color: $theme-color;
$subtitle-color: $theme-color;
$navbar-breakpoint: 320px;

$input-background-color: $theme;
$input-color: $theme-color;


@import "~bulma";
@import "~@creativebulma/bulma-collapsible/dist/css/bulma-collapsible.min.css";
@import '~react-flags-select/scss/react-flags-select.scss';

.flag-select {
  background-color: white !important;
  min-width: 174px; }

.flag-select__option__label {
  color: black; }

.filterBox {
  input {
    color: black; } }

body {
  background-color: #262733;
  background-image: url('../Common/Images/background.png');
  background-repeat: no-repeat;
  background-position: right;
  font-family: Roboto, sans-serif; }
//.navbar-item
//width: 100%
.ant-steps-item-process .ant-steps-item-icon {
  background-color: $theme-red; }
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: $theme-red; }
.ant-steps-item-wait .ant-steps-item-icon {
  color: $theme-red; }
.ant-steps-item-wait .ant-steps-item-icon {
  color: black; }
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: white; }
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: white; }
.navbar-item img {
  max-width: 212px;
  max-height: inherit; }
.product-card-header {
  position: relative;
  margin-top: -30%;
  padding: 8%;
  border-radius: 9px;
  font-weight: bold;
  color: $theme-color; }
.is-padded-top {
  margin-top: 10%; }
.is-padded-top.less {
  margin-top: 5%; }
.is-padded-bottom {
  margin-bottom: 12%; }
.flex-end {
  align-self: flex-end;
  width: 100%; }
.button .is-danger {
  background-color: #FF5152; }
.is-gapped {
  margin: 2rem; }
.input {
  padding: 2rem;
  border-width: 5px;
  border-radius: 22px; }
#top {
  padding-bottom: 7%; }
.is-not-selected {
  background-color: #881A1A; }
.pricing-card {
  border-radius: 7px; }
.next-button {
  padding: 24px; }
/* Search Box CSS */

.search-box {
  min-height: 100px;
  .search-bar {
    border-radius: 11px;
    overflow: hidden;
    .ant-select {
      width: 100%; }
    input {
      padding: 10px 16px; }
    .ant-select-selection-item {
      display: none; } }
  .search-selection {
    margin-top: 40px;
    overflow: hidden; }
  .selected-item {
    position: relative;
    text-align: center;
    padding: 10px;
    margin: 10px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    .close {
      cursor: pointer;
      position: absolute;
      color: #bdbdbd;
      top: 5px;
      right: 5px;
      &:hover {
        color: #757575;
        transition: all 0.3s; } }
    .avatar {
      width: 50px;
      height: 50px;
      margin: 10px;
      background: #fff;
      border: 1px solid #7E8CA6;
      border-radius: 40px; }
    &.musician .avatar {
      border-radius: 50%; }
    .avatar img {
      width: 100%;
      border-radius: 40px; }
    &.musician .avatar img {
      border-radius: 50%; }
    .title {
      font-size: 19px;
      font-weight: bold; }
    .subtitle {
      font-size: 16px;
      color: #777; } } }

.selected-item-image {
  width: 40px;
  height: 40px;
  background: #666;
  border-radius: 50%; }
.section-padding-less {
  padding: 1rem 1.5rem; }
.word-break {
  word-wrap: normal; }
.is-darkish {
  background-color: #1F1F2A;
  color: $theme-color; }
.padded-vertically {
  margin: 0 12px 12px 12px; }
.has-text-red {
  color: #FF292A; }
.cancel {
  background-color: #FF292A !important;
  color: white; }

.card-button {
  background-color: inherit;
  border: 0;
  color: $theme-color;
  white-space: inherit;
  &:hover {
    color: white; } }
.display-inline {
  display: inline-block; }
.padded-div {
  padding: 5px; }
.card-content {
  .field {
    .label {
      font-size: 116%; } } }
#card-field {
  border: #dbdbdb 5px solid;
  border-radius: 8px;
  padding: 20px; }
@media (max-width: 768px) {
  .card-content {
    padding: 5px; } }

.cursor-pointer {
  cursor: pointer; }

// Pricing
.pricing-card-header {
  padding: 0 2rem 0.4rem;
  margin-top: 20px !important;
  border-top-right-radius: 11px;
  border-top-left-radius: 11px;
  z-index: 1;
  .pricing-title {
    padding: 1rem; } }

@include tablet-only {
  .pricing-card-header {
    padding: 0.4rem;
    .pricing-title {
      padding: 0.5rem; } } }
@include mobile {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    flex-direction: row; }
  .navbar-item img {
    max-height: 152px; }
  .pricing-card-header {
    padding: 0.2rem;
    .pricing-title {
      padding: 0.75rem; } }
  .is-large {
    font-size: 1rem !important; } }
